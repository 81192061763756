import * as React from 'react';
import { graphql } from 'gatsby';
import { Layout } from '../../components';
import styles from './styles/index.module.scss';

export interface GatsbyLocation extends Location {
  state: { [key: string]: string };
}

export interface PageProps {
  location: GatsbyLocation;
  data: any;
  pageContext: any;
}

export interface MarkdownEdge {
  node: {
    fileAbsolutePath: string;
  };
}

export interface MarkdownPage {
  fileAbsolutePath: string;
  excerpt: string;
  frontmatter: {
    title: string;
    image: string;
    nav_menu: string;
    short_description: string;
    component: string;
    order: number | string;
  };
}

const IndexPage: React.FunctionComponent<PageProps> = (props) => {
  const pages = props.data.allMarkdownRemark.edges
    .map((item: MarkdownEdge) => {
      return item.node;
    })
    .sort(
      (a: MarkdownPage, b: MarkdownPage) =>
        Number(a.frontmatter.order) - Number(b.frontmatter.order)
    );

  return (
    <Layout locale={props.pageContext.locale} location={props.location}>
      <main>
        <section className={[styles.programs].join(' ')}>
          {pages.map((page: MarkdownPage, index: number) => {
            let textPositionClasses = [];
            switch (index % 6) {
              case 1:
              case 3:
                textPositionClasses.push(styles.textImageComponentTopRight);
                break;
              case 2:
              case 4:
                textPositionClasses.push(styles.textImageComponentBottomLeft);
                break;
              case 0:
              case 5:
              default:
                break;
            }

            let linkUrl = page.fileAbsolutePath
              .replace(new RegExp('.*(' + props.location.pathname + ')'), '$1')
              .replace(/\.md$/, '');

            return (
              <a
                className={[styles.programOverview].join(' ')}
                href={linkUrl}
                key={'articlelistitem_' + page.frontmatter.title}
              >
                <div
                  className={[
                    ...textPositionClasses,
                    styles.textImageComponent,
                    styles.textImageComponentSmall,
                  ].join(' ')}
                >
                  <div
                    className={[styles.textImageComponentImage].join(' ')}
                    style={{
                      backgroundImage: `url('${page.frontmatter.image}')`,
                    }}
                  ></div>
                  <article
                    className={[styles.textImageComponentText].join(' ')}
                  >
                    <h5 className={[styles.textImageComponentTitle].join(' ')}>
                      {page.frontmatter.title}
                    </h5>
                    {page.frontmatter.short_description == null ||
                    page.frontmatter.short_description === ''
                      ? page.excerpt
                      : page.frontmatter.short_description}
                  </article>
                </div>
              </a>
            );
          })}
        </section>
      </main>
    </Layout>
  );
};

export const query = graphql`
  query($path__glob: String!) {
    allMarkdownRemark(filter: { fileAbsolutePath: { glob: $path__glob } }) {
      edges {
        node {
          fileAbsolutePath
          excerpt
          frontmatter {
            title
            short_description
            image
            nav_menu
            component
            order
          }
        }
      }
    }
  }
`;

export default IndexPage;
